<template>
  <overlay-base :show="isShown">
    <v-container
      class="pro-success"
      :fill-height="$vuetify.breakpoint.smAndUp"
      restrict-width-wide
      grid-list-md
      justify-center
    >
      <v-layout align-center justify-center :wrap="$vuetify.breakpoint.xsOnly" skr-side-by-side layout-3>
        <v-flex xs12 md5>
          <v-layout column :align-center="$vuetify.breakpoint.xsOnly">
            <heading
              :center="$vuetify.breakpoint.xsOnly"
              :title="$t('billing.settings.overlay.downgrade.title')"
              :subtitle="$t('billing.settings.overlay.downgrade.text')"
            />
            <responsive-image v-if="$vuetify.breakpoint.xsOnly" source="welcome-aes" :width="450" :height="496" />
            <v-btn :class="{ 'mt-5': $vuetify.breakpoint.xsOnly }" block color="info" large @click="close">
              {{ $t('billing.settings.overlay.downgrade.cta') }}
            </v-btn>
          </v-layout>
        </v-flex>

        <v-flex v-if="$vuetify.breakpoint.smAndUp" xs12 md6 offset-md1>
          <v-layout column align-center>
            <responsive-image source="welcome-aes" :width="450" :height="496" />
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
  </overlay-base>
</template>

<script scoped lang="ts">
import Vue from 'vue'

import Heading from '~/components/Heading.vue'
import ResponsiveImage from '~/components/ResponsiveImage.vue'

import OverlayBase from './OverlayBase.vue'

export default Vue.extend({
  name: 'OverlayFreeDowngrade',
  components: {
    Heading,
    ResponsiveImage,
    OverlayBase,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isShown(): boolean {
      return (this.$store.state as RootState).overlays.downgrade
    },
  },
  methods: {
    close() {
      void this.$store.dispatch('closeOverlay', 'downgrade')
    },
  },
})
</script>
